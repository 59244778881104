var subscriptions = {};
var capitalize = function (string) { return string.replace(/^\p{CWU}/u, function (char) { return char.toUpperCase(); }); };
var complainAboutMissingPort = function (portName) {
    return console.error("Port '".concat(portName, "' isn't exposed by the Elm app. Perhaps it was dead code eliminated? You should most likely remove the subscribe").concat(capitalize(portName), " and unsubscribe").concat(capitalize(portName), " calls from the index.js file."));
};
export var subscribe = function (portName, fn) { return function (app) {
    if (portName in app.ports) {
        subscriptions[portName] = fn(app);
        app.ports[portName].subscribe(subscriptions[portName]);
    }
    else {
        complainAboutMissingPort(portName);
    }
}; };
export var unsubscribe = function (portName, fn) { return function (app) {
    if (portName in app.ports) {
        app.ports[portName].unsubscribe(subscriptions[portName]);
        delete subscriptions[portName];
    }
    else {
        complainAboutMissingPort(portName);
    }
}; };
export var handlers = function (portHandlers) {
    /* Expects to be called like
     *
     *   handlers({
     *     portName1: (app) => (portArgs) => {...},
     *     portName2: (app) => (portArgs) => {...},
     *   });
     *
     * (The app argument is there if you need to send messages through JS->Elm ports.)
     *
     * Creates object with fns like:
     *
     *   {
     *     subscribePortName1: (app) => {...},
     *     subscribePortName2: (app) => {...},
     *     unsubscribePortName1: (app) => {...},
     *     unsubscribePortName2: (app) => {...},
     *   }
     *
     * which you can then run where you deal with the Elm `app` and ports.
     */
    return Object.keys(portHandlers).reduce(function (acc, portName) {
        var capitalizedPortName = capitalize(portName);
        var handler = portHandlers[portName];
        acc["subscribe".concat(capitalizedPortName)] = subscribe(portName, handler);
        acc["unsubscribe".concat(capitalizedPortName)] = unsubscribe(portName, handler);
        return acc;
    }, {});
};
