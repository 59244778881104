export default (function (ENV) {
    return new Promise(function (resolve) {
        // @ts-ignore
        if (document.interestCohort !== undefined) {
            document
                // @ts-ignore
                .interestCohort()
                .then(function (cohort) {
                ENV.cohort = cohort;
                resolve(ENV);
            })
                .catch(function (e) {
                resolve(ENV);
            });
        }
        else {
            resolve(ENV);
        }
    });
});
