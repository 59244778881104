import * as d3 from "d3";
import d3Charts from "d3-charts-2";
import ResizeObserver from "resize-observer-polyfill";
import { allMetrics } from "../chart-metrics";
var ro = new ResizeObserver(function (entries, observer) {
    // We wrap it in requestAnimationFrame to avoid this error - ResizeObserver loop limit exceeded
    window.requestAnimationFrame(function () {
        if (!Array.isArray(entries) || !entries.length) {
            return;
        }
        for (var k in entries) {
            if (entries.hasOwnProperty(k)) {
                var target = entries[k].target;
                if (target.redraw) {
                    target.redraw.call(target);
                }
            }
        }
    });
});
var sumLabelText = function (metric) {
    switch (metric) {
        case "percentage":
            return "Total Audience %";
        case "weighted_universe_count":
            return "Total Universe";
        default:
            return "Total";
    }
};
function init() {
    var _this = this;
    // This function should be called whenever chart-type attribute changes
    if (this.chart) {
        this.chart.remove();
        this.chart = null;
    }
    var chartType = this.getAttribute("chart-type");
    var metric = this.getAttribute("metric");
    var data = JSON.parse(this.getAttribute("data"));
    var scaleMaxes = JSON.parse(this.getAttribute("scale-maxes"));
    var tooltipDebounce;
    // Elm has to handle this event and decide what to do about it.
    var tipHook = function (state) {
        clearTimeout(tooltipDebounce);
        if (state.isVisible) {
            tooltipDebounce = setTimeout(function () {
                // @ts-ignore
                return xtag.fireEvent(_this, "tracktooltipshown", {
                    detail: state.data.name
                });
            }, 1500);
        }
    };
    this.chart = d3Charts
        .getDashboardChart(chartType)(tipHook)
        .metric(metric)
        .scaleMaxes(scaleMaxes)
        .data(data);
    if (chartType === "donut_chart") {
        // TODO this doesn't work
        this.chart.setSumLabel(sumLabelText(metric));
    }
}
function redraw() {
    if (!this.chart) {
        return;
    }
    var metric = this.getAttribute("metric");
    var data = JSON.parse(this.getAttribute("data"));
    var legendData = JSON.parse(this.getAttribute("legend-data"));
    var scaleMaxes = JSON.parse(this.getAttribute("scale-maxes"));
    this.chart.data(data);
    if (this.chart.legendData) {
        this.chart.legendData(legendData);
    }
    this.chart.metric(metric).scaleMaxes(scaleMaxes);
    var chartMetric = this.chart.metric(metric);
    chartMetric &&
        chartMetric.axisLabel &&
        chartMetric.axisLabel(this.getMetricName(metric));
    d3.select(this).select("svg").call(this.chart);
}
/* <x-dashboards-chart-2
 *      chart-type="donut_chart"
 *      metric="percentage"
 *      data="..."
 *      scale-maxes="..."
 *  />
 */
// @ts-ignore
xtag.register("x-dashboards-chart-2", {
    content: "<svg></svg>",
    lifecycle: {
        created: function () {
            var _this = this;
            if (this.initCheckDebounce)
                clearTimeout(this.initCheckDebounce);
            this.initCheckDebounce = setTimeout(function () {
                init.call(_this);
                redraw.call(_this);
                ro.observe(_this);
            }, 200);
        },
        inserted: function () {
            if (this.initCheckDebounce)
                clearTimeout(this.initCheckDebounce);
            this.init();
            this.redraw();
            ro.observe(this);
        },
        removed: function () {
            ro.unobserve(this);
            this.chart.remove();
            this.chart = null;
        },
        attributeChanged: function (attrName, _, newValue) {
            switch (attrName) {
                case "chart-type":
                    this.init();
                case "data":
                case "legend-data":
                case "metric":
                case "size-height":
                case "size-width":
                    this.redraw();
                    break;
            }
        }
    },
    methods: {
        redraw_: function () {
            redraw.call(this);
        },
        redraw: function () {
            var _this = this;
            if (this.redrawDebounce)
                clearTimeout(this.redrawDebounce);
            this.redrawDebounce = setTimeout(function () { return _this.redraw_(); }, 100);
        },
        init: function () {
            init.call(this);
        },
        getMetricName: function (id) {
            for (var i in allMetrics) {
                var metric = allMetrics[i];
                if (metric.id === id) {
                    return metric.name;
                }
            }
            return;
        }
    }
});
