import Port from "../../_port/lift";
import { monkeyPatchXtag } from "../../_helpers/webcomponents";
import { getEnvironment, removeUrlParam } from "../../_initializer/helpers";
// Initializer
import analyticsInit from "../../_initializer/analytics";
import repographicsAnalyticsInit from "../../_initializer/repographics-analytics";
import currentUserInit from "../../_initializer/current-user";
import { silentTokenRefresh } from "../../_initializer/refresh-token";
import intercomInit from "../../_initializer/intercom";
import keysInit from "../../_initializer/keys";
import p2UrlInit from "../../_initializer/p2-url";
import legacyAppPortsInit from "../../_initializer/legacy-app-ports";
import intercomPortsInit from "../../_initializer/intercom-ports";
import xb20Init from "../../_initializer/xb20-ports";
import dashboards20Init from "../../_initializer/dashboards20-ports";
import tv20Init from "../../_initializer/tv20-ports";
import flocInit from "../../_initializer/floc";
// Ports
import windowPorts from "../../_port/window";
import clipboardPorts from "../../_port/clipboard";
import analyticsPorts from "../../_port/analytics";
import localStoragePorts from "../../_port/localStorage";
import cookiesPorts from "../../_port/cookies";
import intercomPorts from "../../_port/intercom";
import beforeUnloadConfirmPorts from "../../_port/beforeunload-confirm";
import dragStartPorts from "../../_port/dragStart";
import selectTextInFieldPorts from "../../_port/selectTextInField";
import scrollPorts from "../../_port/scroll";
require("@webcomponents/webcomponentsjs/webcomponents-bundle.js");
require("@webcomponents/webcomponentsjs/custom-elements-es5-adapter.js");
// Assets
require("../../_legacy/dist/assets/app.scss");
require("./style.scss");
require("./index.html");
// Environment
var ENV = {
    environment: process.env.TARGET_ENV,
    feature: process.env.FEATURE_BRANCH,
    app_name: "pro-next"
};
var searchParams = new URLSearchParams(document.location.search);
var isHeadlessTest = searchParams.get("headless-test") === "1";
// @ts-ignore
ENV.is_headless_test = isHeadlessTest;
if (isHeadlessTest) {
    removeUrlParam("headless-test");
}
// We don't need to use helpers.getEnvironment(ENV) here because we're
// explicitly setting the `ENV.environment` variable a few lines above.
// @ts-ignore
if (ENV.environment !== "development" && !isHeadlessTest && !!window.Sentry) {
    // @ts-ignore
    Sentry.init({
        dsn: "https://313cc4a1ad504946a183e108499091df@sentry.io/3048031",
        environment: ENV.environment,
        attachStacktrace: true,
        beforeBreadcrumb: function (breadcrumb, hint) {
            // We want to make the request and response data clearly visible in Sentry.
            //
            // We can't get the request data here as the XmlHttpRequest class doesn't
            // allow that -- but thankfully Sentry already gives us that in the
            // AjaxError part of the report, under `data` field.
            //
            // So here we do what we can: make the response always be there, and
            // nicely visible in the Sentry UI inside the breadcrumb.
            //
            // Note: we don't want to send too long responses (like from /questions).
            if (breadcrumb.category === "xhr") {
                if (hint.xhr.responseText.length < 1024) {
                    breadcrumb.data.response = hint.xhr.responseText;
                }
                else {
                    breadcrumb.data.response = "Too long to send to Sentry! (".concat(hint.xhr.responseText.length, " bytes long)");
                }
            }
            return breadcrumb;
        }
    });
}
// X-tags
if (typeof window === "object") {
    // @ts-ignore
    window.xtag = require("x-tag");
    monkeyPatchXtag();
}
require("../../_x-tag/x-gemini-scrollbar/component.js");
require("../../_x-tag/x-gemini-scrollbar/style.scss");
require("../../_x-tag/x-cb-vertical-bar-chart/component.js");
require("../../_x-tag/x-cb-vertical-bar-chart/style.scss");
require("../../_x-tag/x-cb-horizontal-bar-chart/component.js");
require("../../_x-tag/x-cb-horizontal-bar-chart/style.scss");
require("../../_x-tag/x-dashboards-chart/component.js");
require("../../_x-tag/x-dashboards-chart/style.scss");
require("../../_x-tag/x-dashboards-chart-2/component");
require("../../_x-tag/x-dashboards-chart-2/style.scss");
require("../../_x-tag/x-sticky/component.js");
require("../../_x-tag/x-tryfit/component.js");
require("../../_x-tag/x-tryfit/style.scss");
require("../../_x-tag/x-legacy/component.js");
require("../../_x-tag/x-paste-input/component.js");
// P2.0 web components
require("../../webcomponents");
require("../../custom-elements/x-cooltip/component.ts");
require("../../custom-elements/x-cooltip/style.scss");
require("../../custom-elements/x-simplebar/component.ts");
require("../../custom-elements/x-simplebar/style.scss");
require("../../custom-elements/x-d3scription/component.ts");
require("../../custom-elements/x-resize-observer/component.ts");
// Helpers
function Init(ENV) {
    return new Promise(function (resolve) {
        resolve(ENV);
    });
}
Init(ENV)
    .then(keysInit)
    .then(p2UrlInit)
    .then(currentUserInit)
    .then(flocInit)
    .then(analyticsInit)
    .then(repographicsAnalyticsInit)
    .then(intercomInit)
    .then(function (ENV) {
    // @ts-ignore
    var token = ENV.token;
    return import(
    /* webpackChunkName: "elm" */
    /* webpackMode: "eager" */
    // @ts-ignore
    "./Entry").then(function (_a) {
        var elm = _a.Elm;
        // start app
        return elm.Entry.init({
            flags: {
                token: token,
                env: getEnvironment(ENV),
                // @ts-ignore
                user: ENV.user,
                // @ts-ignore
                feature: ENV.feature,
                helpMode: window.localStorage.helpMode == "true",
                // @ts-ignore
                platform: ENV.app_name,
                revision: searchParams.get("revision"),
                referrer: document.referrer,
                platform2Url: ENV.platform2Url
            }
        });
    });
})
    .then(function (app) {
    legacyAppPortsInit(app); // exposing Elm ports to Ember
    intercomPortsInit(app); // intercom -> Elm communication
    silentTokenRefresh(app, ENV);
    xb20Init(app); // XB2.0 ports for P2.0 emulation
    dashboards20Init(app); // Dashboards 2.0 ports for P2.0 emulation
    tv20Init(app); // TV2.0 ports for P2.0 emulation
    // subscriptions (Elm -> JS communication)
    Port(app)
        // @ts-ignore
        .port(windowPorts.subscribeOpenNewWindow)
        // @ts-ignore
        .port(windowPorts.subscribeOpenNewWindowD2)
        // @ts-ignore
        .port(windowPorts.subscribeOpenNewWindowXB2)
        // @ts-ignore
        .port(windowPorts.subscribeOpenNewWindowTV2)
        // @ts-ignore
        .port(analyticsPorts.subscribeTrack)
        // @ts-ignore
        .port(analyticsPorts.subscribeBatch)
        // @ts-ignore
        .port(clipboardPorts.subscribeCopyToClipboard)
        // @ts-ignore
        .port(clipboardPorts.subscribeAddHostAndCopyToClipboard)
        // @ts-ignore
        .port(localStoragePorts.subscribeLocalStorageSet)
        // @ts-ignore
        .port(cookiesPorts.subscribeDeleteCookie)
        // @ts-ignore
        .port(intercomPorts.subscribeOpenChat)
        // @ts-ignore
        .port(intercomPorts.subscribeCloseChat)
        .port(
    // @ts-ignore
    beforeUnloadConfirmPorts.subscribeSetConfirmMsgBeforeLeavePage)
        // @ts-ignore
        .port(dragStartPorts.subscribeDragstart)
        // @ts-ignore
        .port(selectTextInFieldPorts.subscribeSelectTextInField)
        // @ts-ignore
        .port(selectTextInFieldPorts.subscribeSelectTextInFieldXB2)
        // @ts-ignore
        .port(scrollPorts.subscribeDebouncedScrollEvent);
});
