var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { register } from "./_helpers/webcomponents";
import { AttributeBrowserLeftWebComponent } from "@globalwebindex/platform2-web-components/lib/attributeBrowserLeft/index.js";
import { AudienceBrowserLeftWebComponent } from "@globalwebindex/platform2-web-components/lib/audienceBrowserLeft/index.js";
import { AudienceExpressionViewerWebComponent } from "@globalwebindex/platform2-web-components/lib/audienceExpressionViewer/index.js";
import { AudienceBuilderWebComponent } from "@globalwebindex/platform2-audience-builder/build/assets/audience-builder";
register("x-et-attribute-browser", AttributeBrowserLeftWebComponent);
register("x-et-audience-browser", AudienceBrowserLeftWebComponent);
register("x-et-audience-expression-viewer", AudienceExpressionViewerWebComponent);
register("x-et-audience-builder", /** @class */ (function (_super) {
    __extends(class_1, _super);
    function class_1() {
        var _this = _super.call(this) || this;
        var nodeElement = _this;
        var reDispatchEvent = function (event) {
            // @ts-ignore
            nodeElement.dispatchEvent(new CustomEvent(event.type, {
                bubbles: false,
                // @ts-ignore
                cancelBubble: true,
                defaultPrevented: true
            }));
        };
        window.addEventListener("audienceBuilderClosed", reDispatchEvent);
        return _this;
    }
    return class_1;
}(AudienceBuilderWebComponent)));
