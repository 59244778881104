import { handlers } from "./helpers";
var track = function (app) { return function (arg) {
    var eventName = arg[0];
    var properties = arg[1];
    // window.analytics is defined by _initializer/analytics.js
    // @ts-ignore
    window.analytics.track(eventName, properties);
}; };
var batch = function (app) { return function (events) {
    // window.analytics is defined by _initializer/analytics.js
    // @ts-ignore
    window.analytics.batch(events);
}; };
export default handlers({
    track: track,
    batch: batch
});
