var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import ResizeObserver from "resize-observer-polyfill";
import { register } from "../../_helpers/webcomponents";
/**
 * <x-resize-observer target-selector=".my-target">
 *   <!-- whatever HTML you put inside -->
 *   <div>
 *     ...
 *     <div class="my-target"> ... </div>
 *     ...
 *   </div>
 * </x-resize-observer>
 *
 * will send an event "targetresize" with {detail: {width: Int, height: Int}} JSON.
 *
 * It can even be the element itself!
 * <x-resize-observer class="here" target-selector=".here">
 * </x-resize-observer>
 *
 * NOTE:
 * The targeted element needs to have some height (can be usually done using
 * `height: 100%` or something similar).
 * You can check this using the web inspector. If you're getting height: 0 in
 * the events, you'll see an element with height 0 in the web inspector too and
 * can experiment with how to make it have proper height.
 */
var XResizeObserver = /** @class */ (function (_super) {
    __extends(XResizeObserver, _super);
    function XResizeObserver() {
        var _this = _super.call(this) || this;
        _this.propagateValueToCss = false;
        return _this;
    }
    Object.defineProperty(XResizeObserver, "observedAttributes", {
        get: function () {
            return ["target-selector", "propagate-values-to-css"];
        },
        enumerable: false,
        configurable: true
    });
    XResizeObserver.prototype.connectedCallback = function () {
        var _this = this;
        setTimeout(function () {
            // We wrap it in requestAnimationFrame to avoid this error - ResizeObserver loop limit exceeded
            _this.resizeObserver = new ResizeObserver(function (entries) {
                window.requestAnimationFrame(function () {
                    if (!Array.isArray(entries) || !entries.length) {
                        return;
                    }
                    for (var _i = 0, entries_1 = entries; _i < entries_1.length; _i++) {
                        var entry = entries_1[_i];
                        var dimensions = {
                            width: entry.contentRect.width,
                            height: entry.contentRect.height
                        };
                        if (entry.borderBoxSize) {
                            // new spec
                            // see https://developer.mozilla.org/en-US/docs/Web/API/ResizeObserverEntry
                            // Even with the new spec there are compatibility problems.
                            // Firefox 83 sends an inlined object; Chrome 86 sends an array.
                            // Sigh. Let's support both variants.
                            var borderBoxSize = Array.isArray(entry.borderBoxSize)
                                ? entry.borderBoxSize[0]
                                : entry.borderBoxSize;
                            dimensions = {
                                // These two should be swapped if your `writing-mode` is vertical - we do not use it in our codebase, keeping the comment here just in case it will happen
                                width: borderBoxSize.inlineSize,
                                height: borderBoxSize.blockSize
                            };
                        }
                        // with Elm the observer runs quite often with 0 dimensions first and then with
                        // proper values - we want to ignore the 0 dimensions cases as they were
                        // delivered later and mess with change events order.
                        if (dimensions.width !== 0 && dimensions.height !== 0) {
                            _this.dispatchEvent(new CustomEvent("targetresize", {
                                detail: dimensions,
                                bubbles: false
                            }));
                            var element_1 = document.querySelector(_this.observedSelector);
                            if (_this.propagateValueToCss && element_1) {
                                //@ts-ignore
                                element_1.style.setProperty("--element-width", dimensions.width + "px");
                                //@ts-ignore
                                element_1.style.setProperty("--element-height", dimensions.height + "px");
                            }
                        }
                    }
                });
            });
            var element = document.querySelector(_this.observedSelector);
            if (_this.propagateValueToCss && element) {
                window.requestAnimationFrame(function () {
                    var d = element.getBoundingClientRect();
                    //@ts-ignore
                    element.style.setProperty("--element-width", d.width + "px");
                    //@ts-ignore
                    element.style.setProperty("--element-height", d.height + "px");
                });
            }
            if (element) {
                _this.resizeObserver.observe(element);
            }
        }, 0);
    };
    XResizeObserver.prototype.disconnectedCallback = function () {
        var element = document.querySelector(this.observedSelector);
        if (element && this.resizeObserver) {
            this.resizeObserver.unobserve(element);
        }
        this.observedSelector = null;
    };
    XResizeObserver.prototype.attributeChangedCallback = function (attrName, _, newValue) {
        if (attrName === "target-selector" && newValue) {
            var element = document.querySelector(this.observedSelector);
            if (this.resizeObserver && element) {
                this.resizeObserver.unobserve(element);
            }
            this.observedSelector = newValue;
            var newElement = document.querySelector(this.observedSelector);
            if (this.resizeObserver && newElement) {
                this.resizeObserver.observe(newElement);
            }
        }
        else if (attrName === "propagate-values-to-css" && newValue) {
            this.propagateValueToCss = newValue;
        }
    };
    return XResizeObserver;
}(HTMLElement));
register("x-resize-observer", XResizeObserver);
