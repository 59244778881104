import { getEnvironment } from "./helpers";
export default (function (ENV) {
    var environment = getEnvironment(ENV);
    switch (environment) {
        case "production":
            ENV.keys = {
                intercom: "f0q8xhcx"
            };
            break;
        case "testing":
        case "staging":
        case "development":
            ENV.keys = {
                intercom: "zj1fxfxa"
            };
            break;
        default:
            throw "Unknown environment: \"".concat(environment, "\"!");
    }
    return ENV;
});
