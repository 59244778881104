import { handlers } from "./helpers";
import Intercom from "@globalwebindex/platform2-lib/dist/intercom";
var openChat = function (app) { return function () {
    Intercom.show();
}; };
var closeChat = function (app) { return function () {
    Intercom.hide();
}; };
var openChatWithErrorId = function (app) { return function (errorId) {
    if (errorId !== null) {
        Intercom.showWithMessage("Trace ID: ".concat(errorId, " \n\n Hi, can you assist me with the above issue? \uD83D\uDC4B"));
    }
    else {
        Intercom.show();
    }
}; };
export default handlers({
    openChat: openChat,
    closeChat: closeChat,
    openChatWithErrorId: openChatWithErrorId
});
