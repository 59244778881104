var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { register } from "../../_helpers/webcomponents";
var ACTIVE_ELLIPSIS_CLASS = "active-ellipsis";
var isEllipsisActive = function (e) {
    return e.offsetWidth < e.scrollWidth || e.offsetHeight < e.scrollHeight;
};
/**
 * <x-cooltip> shows a tooltip next to your mouse when hovering a target.
 *
 * Usage:
 * ------
 * There are three different ways to use <x-cooltip>, corresponding to the type
 * CoolTip.Type:
 *
 *   1) Normal
 *   2) RelativeAncestor
 *   3) Global
 *
 *
 * 1) Normal:
 *      Should be the default, first thing to try to use.
 *
 * <x-cooltip offset="10"><!-- offset is optional -->
 *
 *     <x-cooltip-target>
 *         <... whatever you want the user to hover to show the tooltip ...>
 *     </x-cooltip-target>
 *
 *     <x-cooltip-tooltip>
 *         <... content of the tooltip, shown only when hovered ...>
 *     </x-cooltip-tooltip>
 *
 * </x-cooltip>
 *
 *
 * 2) RelativeAncestor:
 *      Used whenever there is some other relatively-positioned ancestor between
 *      your target element and the one you want to base the CoolTip position on.
 *
 *      This way you can say which element to look at for the mouse coordinates.
 *
 * <x-cooltip ... relative-ancestor-selector=".foo__bar--baz">
 *     ...as before...
 * </x-cooltip>
 *
 *
 * 3) Global:
 *      Moves the tooltip element to <body>.
 *
 *      Dangerous(?) option that gambles with the Elm virtual DOM invariants, but
 *      it has proven to be a way out of some tricky situations (looking at you,
 *      XB2 table + lazy loading + scrolling).
 *
 *      Use as a last resort!
 *
 * <x-cooltip ... global="true"><!-- or anything else, the value is not checked -->
 *     ...as before...
 * </x-cooltip>
 *
 *
 * !! WARNING: RANT ABOUT CSS AND INTERNAL IMPLEMENTATION BELOW !!
 *
 * To show the cooltip next to the mouse pointer and to the hovered target
 * element, we'd normally use a `position: relative` style on the cooltip wrapper,
 * but this conflicts with the Gemini toolbar's `overflow: hidden`.
 *
 * You can only break out of that `overflow: hidden` if the first
 * `position: relative` ancestor is also an ancestor of that `overflow: hidden`
 * element.
 *
 * Hence we need a different approach than `position: relative`: we're catching
 * `mousemove` events and tracking the mouse position. Then we have the cooltip
 * have a `position: fixed` (which works on the whole window, not some parent
 * element) and give it that position dynamically through
 * `transform: translate(...)`.
 *
 * The reason we don't just do that in Elm is that the `mousemove` listener would
 * have to be registered globally, even when no cooltips are shown.
 *
 * With WebComponent, we can do that dynamically with `mouseover` and `mouseout`.
 * We could do the same thing in Elm, but it would be managed then, and that's
 * a huge hassle. WebComponents will keep it encapsulated.
 *
 * Note that for `position: fixed` to work, no parent elements can have
 * `transform: translate(...)`. (Yeah, it's a mess.)
 */
var XCooltip = /** @class */ (function (_super) {
    __extends(XCooltip, _super);
    function XCooltip() {
        var _this = _super.call(this) || this;
        _this.updateTooltip = function (fn) {
            if (_this.isGlobal) {
                fn(_this.clone);
            }
            else {
                fn(_this.tooltip);
            }
        };
        _this.mouseEnter = _this.mouseEnter.bind(_this);
        _this.mouseLeave = _this.mouseLeave.bind(_this);
        return _this;
    }
    Object.defineProperty(XCooltip, "observedAttributes", {
        get: function () {
            return ["offset", "global", "relative-ancestor-selector", "show-when-ellipsis"];
        },
        enumerable: false,
        configurable: true
    });
    XCooltip.prototype.connectedCallback = function () {
        this.showWhenEllipsis = this.hasAttribute("show-when-ellipsis");
        this.tooltip = this.querySelector("x-cooltip-tooltip");
        if (this.hasAttribute("offset")) {
            var offset = this.getAttribute("offset");
            this.tooltip.style.setProperty("--cooltip-offset", "".concat(offset, "px"));
        }
        if (this.hasAttribute("relative-ancestor-selector")) {
            this.ancestor = document.querySelector(this.getAttribute("relative-ancestor-selector"));
        }
        else if (this.hasAttribute("global")) {
            this.isGlobal = true;
            this.clone = this.tooltip.cloneNode(true);
            document.querySelector("body").appendChild(this.clone);
            this.tooltip.style.display = "none";
        }
        this.addEventListener("mouseenter", this.mouseEnter);
        this.addEventListener("mouseleave", this.mouseLeave);
    };
    XCooltip.prototype.attributeChangedCallback = function (attrName, oldValue, newValue) {
        if (attrName === "offset") {
            if (!this.tooltip) {
                return; // children weren't yet connected properly
            }
            this.updateTooltip(function (el) {
                return el.style.setProperty("--cooltip-offset", "".concat(newValue, "px"));
            });
        }
    };
    XCooltip.prototype.disconnectedCallback = function () {
        if (this.tooltip && this.isGlobal) {
            this.clone.remove();
        }
        if (this.resizeObserver) {
            this.resizeObserver.unobserve(this);
            this.ellipsisElement = null;
        }
        this.removeEventListener("mouseenter", this.mouseEnter);
        this.removeEventListener("mouseleave", this.mouseLeave);
    };
    XCooltip.prototype.mouseEnter = function (event) {
        var _this = this;
        if (!this.tooltip) {
            return; // children weren't yet connected properly
        }
        if (this.isGlobal) {
            //this is to change the content of the tooltip if the user renames it
            var tooltipGlobal = this.tooltip.cloneNode(true);
            this.clone.remove();
            this.clone = tooltipGlobal;
            document.querySelector("body").appendChild(this.clone);
        }
        if (this.showWhenEllipsis) {
            var ellipsisElement = this.querySelector(this.getAttribute("show-when-ellipsis"));
            var showTooltip = ellipsisElement && isEllipsisActive(ellipsisElement);
            if (showTooltip) {
                ellipsisElement.classList.add(ACTIVE_ELLIPSIS_CLASS);
                this.updateTooltip(function (el) {
                    el.style.display = "";
                });
            }
            else {
                this.updateTooltip(function (el) {
                    el.style.display = "none";
                });
                if (ellipsisElement) {
                    ellipsisElement.classList.remove(ACTIVE_ELLIPSIS_CLASS);
                }
                return;
            }
        }
        var cooltipTarget = event.target.querySelector("x-cooltip-target");
        var childElement = cooltipTarget.childNodes &&
            cooltipTarget.childNodes.length === 1 &&
            cooltipTarget.childNodes[0];
        var rect = childElement
            ? childElement.getBoundingClientRect()
            : event.target.getBoundingClientRect();
        var ancestorRect = this.ancestor ? this.ancestor.getBoundingClientRect() : null;
        var ancestorX = ancestorRect ? ancestorRect.x : 0;
        var ancestorY = ancestorRect ? ancestorRect.y : 0;
        this.updateTooltip(function (el) {
            el.style.setProperty("--cooltip-target-x", "".concat(rect.x - ancestorX, "px"));
            el.style.setProperty("--cooltip-target-y", "".concat(rect.y - ancestorY, "px"));
            el.style.setProperty("--cooltip-target-width", "".concat(rect.width, "px"));
            el.style.setProperty("--cooltip-target-height", "".concat(rect.height, "px"));
            el.style.opacity = 0; //avoid visible jumping till is width and height set
            if (_this.isGlobal) {
                // This 'block' will bite us in ass one day. Most cooltips have 'flex'.
                el.style.display = "block";
            }
        });
        this.updateTooltip(function (el) {
            setTimeout(function () {
                var elRect = _this.isGlobal
                    ? _this.clone.getBoundingClientRect()
                    : el.getBoundingClientRect();
                el.style.opacity = 1;
                el.style.setProperty("--cooltip-width", "".concat(elRect.width, "px"));
                el.style.setProperty("--cooltip-height", "".concat(elRect.height, "px"));
            }, 100);
        });
    };
    XCooltip.prototype.mouseLeave = function (event) {
        var _this = this;
        if (!this.tooltip) {
            return; // children weren't yet connected properly
        }
        this.updateTooltip(function (el) {
            el.style.removeProperty("--cooltip-target-x");
            el.style.removeProperty("--cooltip-target-y");
            el.style.removeProperty("--cooltip-target-width");
            el.style.removeProperty("--cooltip-target-height");
            el.style.removeProperty("--cooltip-width");
            el.style.removeProperty("--cooltip-height");
            if (_this.isGlobal) {
                el.style.display = "none";
            }
        });
    };
    return XCooltip;
}(HTMLElement));
register("x-cooltip", XCooltip);
