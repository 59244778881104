import { handlers } from "./helpers";
var selectTextInField = function (app) { return function (elementId) {
    try {
        // @ts-ignore
        document.getElementById(elementId).select();
    }
    catch (e) { }
}; };
export default handlers({
    selectTextInField: selectTextInField,
    selectTextInFieldXB2: selectTextInField
});
