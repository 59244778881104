import { handlers } from "./helpers";
var copyToClipboard = function (app) { return function (text) {
    navigator.clipboard.writeText(text);
}; };
var addHostAndCopyToClipboard = function (app) { return function (url) {
    navigator.clipboard.writeText(window.location.origin + url);
}; };
export default handlers({
    copyToClipboard: copyToClipboard,
    addHostAndCopyToClipboard: addHostAndCopyToClipboard
});
