import { handlers } from "./helpers";
var beforeLeaveHandler = function (e) {
    // @ts-ignore
    if (window._beforeLeaveConfirmMsg) {
        e.preventDefault();
        // @ts-ignore
        e.returnValue = window._beforeLeaveConfirmMsg; // Gecko and Trident
        // @ts-ignore
        return window._beforeLeaveConfirmMsg; // Gecko and WebKit
    }
    else {
        return true;
    }
};
var setConfirmMsgBeforeLeavePage = function (app) { return function (params) {
    var msg = (params && params.msg) || false;
    // @ts-ignore
    window._beforeLeaveConfirmMsg = msg;
    if (!window.addEventListener || !window.removeEventListener) {
        return;
    }
    if (msg !== false) {
        window.addEventListener("beforeunload", beforeLeaveHandler, false);
    }
    else {
        window.removeEventListener("beforeunload", beforeLeaveHandler, false);
    }
}; };
export default handlers({
    setConfirmMsgBeforeLeavePage: setConfirmMsgBeforeLeavePage
});
