import Intercom from "@globalwebindex/platform2-lib/dist/intercom";
export default (function (ENV) {
    if (!ENV.keys.intercom || !ENV.user || ENV.user.plan_handle == "student") {
        return ENV;
    }
    var intercomData = {
        app_id: ENV.keys.intercom,
        email: ENV.user.email,
        user_hash: ENV.user.intercom_user_hash,
        platformVersion: "Platform 1"
    };
    Intercom && Intercom.boot && Intercom.boot(intercomData);
    return ENV;
});
