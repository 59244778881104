const configEncoded = require("../../_legacy/dist/assets/config");
const version = require("../../_legacy/dist/assets/version");
const config = JSON.parse(decodeURIComponent(configEncoded));

function generateConfigParams(cnf) {
    const head = document.querySelector("head");
    let meta = document.createElement("meta");
    meta.name = "charts/config/environment";
    meta.content = encodeURIComponent(JSON.stringify(cnf));
    head.appendChild(meta);
}

function createScript(url) {
    const head = document.querySelector("head");
    let script = document.createElement("script");
    script.setAttribute("type", "text/javascript");
    script.setAttribute("src", url);
    head.appendChild(script);
}

function removeHeaderConfig() {
    const elm = document.querySelector('meta[name*="charts/config/environment"]');
    if (elm) {
        elm.parentElement.removeChild(elm);
    }
}

function runApp() {
    //Here we can prepare config for Ember app which will be added to header
    let newConfig = Object.assign(
        {
            token: this.flags.token
        },
        config
    );

    newConfig.environment = this.flags.env;
    newConfig.rootElement = "#ember-app";
    newConfig.user = this.flags.user;
    newConfig.initialPath = this.currentPathname || "";
    newConfig.locationType = "none";
    newConfig.APP.uri.api = this.apiurls.core;
    newConfig.manifestPrefix = this.flags.feature;
    newConfig.baseURL = this.flags.feature ? this.flags.feature : "/";
    removeHeaderConfig();
    generateConfigParams(newConfig);

    //When x-tag is destroyed but Ember app js file is already loaded.
    if (window.Charts) {
        // Can't be called twice, has to be initialised globally
        // see Error: Cannot re-register -- https://github.com/emberjs/ember.js/issues/12231
        console.error(
            "Chart (Ember) is already loaded, use x-legacy (Ember.elm) only once as global element."
        );
    } else {
        createScript("/assets/legacy/app.js?" + version);
    }
    this.running = true;
}

xtag.register("x-legacy", {
    lifecycle: {
        created: function () {
            window.EmbeddedEmber = window.EmbeddedEmber || { dataForStoreInit: {} };
        },

        inserted: function () {
            this.running = false;
            if (this.flags) {
                runApp.apply(this);
            }
        },

        removed: function () {
            removeHeaderConfig();
            this.running = null;
        }
    },

    accessors: {
        flags: {
            get: function () {
                return this._flags;
            },
            set: function (value) {
                if (this.running === false) {
                    runApp.apply(this);
                }
                this._flags = value;
            }
        },
        route: {
            set: function (value) {
                this.currentPathname = this.getTransitionPath(value.pathname || "");
                this.transitionTo(this.currentPathname);
            }
        },
        apiurls: {
            get: function () {
                return this._apiurls;
            },
            set: function (value) {
                this._apiurls = value;
            }
        },
        ismenuopened: {
            set: function (value) {
                this.setMenuOpened(value);
            }
        },
        refreshstore: {
            set: function (value) {
                if (value === true) {
                    if (window.EmbeddedEmber && window.EmbeddedEmber.controller) {
                        Ember.run.next(window.EmbeddedEmber.controller, () => {
                            window.EmbeddedEmber.controller.send("refreshStore");
                        });
                    }
                }
            }
        },
        "store-question": {
            set: function (value) {
                this.setToEmberStore("question", value);
                this._storeQuestion = value;
            }
        },
        "store-category": {
            set: function (value) {
                this.setToEmberStore("category", value);
            }
        },
        "store-query": {
            set: function (value) {
                this.setToEmberStore("query", value);
            }
        },
        "store-audience-folder": {
            set: function (value) {
                this.setToEmberStore("audience-folder", value);
            }
        },
        "store-audience": {
            set: function (value) {
                this.setToEmberStore("audience", value);
            }
        }
    },

    methods: {
        setToEmberStore(key, values) {
            const stringValue = JSON.stringify(values);
            const propertyKey = `_storeString-${key}`;

            if (this[propertyKey] === stringValue) {
                return false;
            }
            this[propertyKey] = stringValue;

            if (window.EmbeddedEmber && window.EmbeddedEmber.setStoreData) {
                // if Ember is initialized already, we can call it directly
                if (values !== null) {
                    window.EmbeddedEmber.setStoreData(key, values);
                }
            } else if (window.EmbeddedEmber && window.EmbeddedEmber.dataForStoreInit) {
                // if Ember isn't initialized yet, we save this data for later
                // and Ember will look at it and process it when it initializes
                window.EmbeddedEmber.dataForStoreInit[key] = values;
            }
        },

        getTransitionPath(pathname) {
            const featureUrl = this.flags.feature ? this.flags.feature + "/" : "";
            return (pathname && String(pathname).replace(featureUrl, "")) || "";
        },

        transitionTo(route) {
            // Ember checks `lastElmRoute` to see if Elm initiated the transition,
            // and doesn't send the location back to Elm through setLocation
            // (because Elm already knows about it!)
            window.EmbeddedEmber.lastElmRoute = route;

            if (window.EmbeddedEmber && window.EmbeddedEmber.controller) {
                window.EmbeddedEmber.controller.send("transitionToRoute", route);
            }
        },

        setMenuOpened(newState) {
            if (window.EmbeddedEmber && window.EmbeddedEmber.controller) {
                const menu =
                    window.EmbeddedEmber.controller.container.lookup("controller:menu");
                if (!menu.isDestroyed && !menu.isDestroying) {
                    Ember.set(menu, "isOpened", newState);
                }
            }
        }
    }
});
