export var getEnvironment = function (ENV) {
    if ("environment" in ENV)
        return ENV.environment;
    if ("env" in ENV)
        return ENV.env;
    throw "The ENV object doesn't contain `env` or `environment` field!";
};
export var host = function (environment) {
    switch (environment) {
        case "production":
            return "https://api.globalwebindex.com";
        case "staging":
            return "https://api-staging.globalwebindex.com";
        case "testing":
            return "https://api-testing.globalwebindex.com";
        case "development":
            return "https://api-testing.globalwebindex.com";
        default:
            throw "Unknown environment: \"".concat(environment, "\"!");
    }
};
export var susiHost = function (environment) {
    switch (environment) {
        case "production":
            return "https://signin.globalwebindex.com";
        case "staging":
            return "https://signin-staging.globalwebindex.com";
        case "testing":
            return "https://signin-testing.globalwebindex.com";
        case "development":
            return "https://signin-testing.globalwebindex.com";
        default:
            throw "Unknown environment: \"".concat(environment, "\"!");
    }
};
export var rmpPanelHost = function (environment) {
    switch (environment) {
        case "production":
            return "https://panel-ui.globalwebindex.com";
        case "staging":
            return "https://panel-ui-staging.globalwebindex.com";
        case "testing":
            return "https://panel-ui-staging.globalwebindex.com"; // staging not a typo
        case "development":
            return "https://panel-ui-staging.globalwebindex.com"; // staging not a typo
        default:
            throw "Unknown environment: \"".concat(environment, "\"!");
    }
};
export var tokenCookieName = function (token_type) { return function (environment) {
    switch (environment) {
        case "production":
            return "".concat(token_type, "_gwi");
        case "staging":
            return "".concat(token_type, "_gwi_staging");
        case "testing":
            return "".concat(token_type, "_gwi_testing");
        case "development":
            return "".concat(token_type, "_gwi_testing");
        // ^ no such cookie for development, but null would lead to problems with Cookies.get(null)
        default:
            throw "Unknown environment: \"".concat(environment, "\"!");
    }
}; };
export var authTokenCookieName = tokenCookieName("auth");
export var refreshTokenCookieName = tokenCookieName("refresh");
export var authMasqueradingTokenCookieName = function (environment) {
    switch (environment) {
        case "production":
            return "auth_gwi_masquerading";
        case "staging":
            return "auth_gwi_staging_masquerading";
        case "testing":
            return "auth_gwi_testing_masquerading";
        case "development":
            return "auth_gwi_development_masquerading";
        // ^ no such cookie for development, but null would lead to problems with Cookies.get(null)
        default:
            throw "Unknown environment: \"".concat(environment, "\"!");
    }
};
export var domain = "globalwebindex.com";
export var authTokenUrlParam = "access_token";
export var refreshTokenUrlParam = "refresh_token";
export var removeUrlParam = function (param) {
    var params = new URLSearchParams(window.location.search);
    params.delete(param);
    // @ts-ignore
    var newLocation = new URL(window.location);
    newLocation.search = params.toString();
    window.history.pushState(null, "Removed URL param", newLocation);
};
export var navigateTo = function (route, send) {
    var location = window.location;
    var routeWithOrigin = route.indexOf("://") > -1 ? route : location.origin + route;
    var url = new URL(routeWithOrigin);
    var query = url.search.substring(1);
    var fragment = url.hash.substring(1);
    if (url.origin !== location.origin) {
        location.href = url.href;
    }
    else {
        send({
            protocolIsHttps: url.protocol === "https:",
            host: url.hostname,
            port_: url.port ? parseInt(url.port) : null,
            path: url.pathname,
            query: query,
            fragment: fragment
        });
    }
};
// Hack to get DOMRect of event target - Thx @wolfadex! ♥
Object.defineProperty(Element.prototype, "__getBoundingClientRect", {
    get: function () {
        return this.getBoundingClientRect();
    },
    configurable: true
});
